
define('template!pimProductPricesTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(useFactorV2) { ;
__p += '\n<h2 style="font-weight: bold; color: green;">This product is using automated pricing</h2>\n<button class="btn-switch-custom-pricing">Switch custom pricing</button>\n';
 } else { ;
__p += '\n<h2 style="font-weight: bold; color: orange;">This product is using custom pricing</h2>\n<button class="btn-switch-automated-pricing">Switch to automated pricing</button>\n';
 } ;
__p += '\n<div class="top-ctn">\n    <div class="row">        \n        <div class="col-lg-2 onOff">\n            <div class="libelle">' +
((__t = ( _.i18n("common.onOff") )) == null ? '' : __t) +
'</div>\n            <div class="value">\n                <input type="checkbox" class="cbx-show-in-webshop" ' +
((__t = ( product.showInWebshop? 'checked':'' )) == null ? '' : __t) +
' />\n            </div>\n        </div>                       \n        <div class="col-lg-2 status">\n            <div class="libelle">' +
((__t = ( _.i18n("common.status") )) == null ? '' : __t) +
'</div>\n            <div class="value">\n                <select class="input-product-status">\n                    ';
 if (product.status !== 'New' && product.status !== 'Best seller' && product.status !== 'Sale' && product.status !== 'Best price' && product.status !== 'Ending' && product.status !== 'Soldout') { ;
__p += '\n                        <option value="' +
((__t = ( product.status )) == null ? '' : __t) +
'">' +
((__t = ( product.status2 )) == null ? '' : __t) +
'</option>\n                    ';
 } ;
__p += '\n                    <option value="New" ' +
((__t = ( (product.status === 'New')?'selected':'' )) == null ? '' : __t) +
'>New</option>\n                    <option value="Best seller" ' +
((__t = ( (product.status === 'Best seller')?'selected':'' )) == null ? '' : __t) +
'>Best seller</option>\n                    <option value="Sale" ' +
((__t = ( (product.status === 'Sale')?'selected':'' )) == null ? '' : __t) +
'>Sale</option>\n                    <option value="Best price" ' +
((__t = ( (product.status === 'Best price')?'selected':'' )) == null ? '' : __t) +
'>Best price</option>\n                    <option value="Ending" ' +
((__t = ( (product.status === 'Ending')?'selected':'' )) == null ? '' : __t) +
'>Ending</option>\n                    <option value="Soldout" ' +
((__t = ( (product.status === 'Soldout')?'selected':'' )) == null ? '' : __t) +
'>Soldout</option>\n                </select>\n            </div>\n        </div>\n        <div class="col-lg-6 comment">\n            <div class="libelle">' +
((__t = ( _.i18n("common.comment") )) == null ? '' : __t) +
'</div>\n            <div class="value">\n                <textarea style="width: 350px" class="input-product-comment">' +
((__t = ( product.comment )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div> \n    </div>                  \n</div>\n<div class="imprint-ctn">     \n    <div class="row">\n        <div class="col-lg-6 col-md-12 col-xs-12">\n            <div class="imprint-table">\n                <div class="table-title">' +
((__t = ( _.i18n("print.imprint") )) == null ? '' : __t) +
'</div>   \n                <div class="table-header">               \n                    <div class="row">\n                        <div class="col-xs-1 index"><div class="col">' +
((__t = ( _.i18n("common.nR") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-1 onOff"><div class="col">' +
((__t = ( _.i18n("common.onOff") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-2 name"><div class="col">' +
((__t = ( _.i18n("print.location") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-3 technic"><div class="col">' +
((__t = ( _.i18n("print.technic") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-1 colors"><div class="col">' +
((__t = ( _.i18n("common.colors") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-3 setup"><div class="col">' +
((__t = ( _.i18n("print.setup") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-1 handling"><div class="col">' +
((__t = ( _.i18n("print.handling") )) == null ? '' : __t) +
'</div></div>\n                    </div>                        \n                </div>  \n                <div class="imprints-region"></div>\n            </div>                \n        </div>\n        <div class="col-lg-6 col-md-12 col-xs-12">\n            <div class="imprint-prices-table">\n                <div class="table-title">' +
((__t = ( _.i18n("print.imprint-prices") )) == null ? '' : __t) +
'</div>                    \n                <div class="table-header">\n                    <div class="row">\n                        ';
 var sizePercent = 100 / (price.scale.length + 1); ;
__p += '\n                        ';
 _.each(price.scale, function(p, index) { ;
__p += '\n                            <div class="col-xs-1 amount" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n                                <div class="col">\n                                    ';
 if ((price.scale.length -1) === index) { ;
__p += '\n                                        ' +
((__t = ( p.quantity )) == null ? '' : __t) +
'+\n                                    ';
 } else { ;
__p += '\n                                        ' +
((__t = ( p.quantity )) == null ? '' : __t) +
'-' +
((__t = ( Number(price.scale[index+1].quantity) -1 )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </div>                                   \n                            </div>                               \n                        ';
 }); ;
__p += '\n                        <div class="col-xs-1 factor" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n                            <div class="col">' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'</div>\n                        </div>\n                    </div>\n                </div>\n                <div class="imprints-prices-region"></div>\n            </div> \n        </div>\n    </div>      \n</div>\n<div class="bottom-ctn">     \n    <div class="row">\n        <div class="col-lg-3 col-md-3 col-xs-12">\n            <div class="calculator-table">\n                <div class="table-title">' +
((__t = ( _.i18n("price.calculator") )) == null ? '' : __t) +
'</div>                    \n                <div class="table-header">\n                    <div class="row">\n                        <div class="col-xs-2 amount"><div class="col">' +
((__t = ( _.i18n("price.amount") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-3 unit-price"><div class="col">' +
((__t = ( _.i18n("price.unit-price") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-4 price"><div class="col">' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-3 factor"><div class="col">' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'</div></div>\n                    </div>\n                </div>\n                <div class="calculator-region"></div>\n                ';
 if (!useFactorV2) { ;
__p += '\n                <button class="btn-copy-to-scale">-></button>\n                ';
 } ;
__p += '\n            </div>                 \n        </div>    \n        <div class="col-lg-9 col-md-9 col-xs-12">    \n            <div class="scales-table">                  \n                <div class="table-title">' +
((__t = ( _.i18n("price.salesPrice") )) == null ? '' : __t) +
'\n                    ';
 if (nbrTechnic > 0) { ;
__p += '\n                        <div class="technic-select">                           \n                            <select class="select-technic">\n                                ';
 for (var i = 1 ; i <= nbrTechnic ; i++) { ;
__p += '\n                                    <option value="' +
((__t = ( i )) == null ? '' : __t) +
'">' +
((__t = ( i )) == null ? '' : __t) +
'</option>\n                                ';
 } ;
__p += '\n                            </select>\n                        </div>\n                    ';
 } ;
__p += '\n                </div>                    \n                <div class="table-header">\n                    <div class="row">\n                        <div class="col-xs-1 amount"><div class="col">' +
((__t = ( _.i18n("price.amount") )) == null ? '' : __t) +
'</div></div>\n                        ';
 if(!useFactorV2) { ;
__p += '\n                        <div class="col-xs-1 price"><div class="col">' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        ';
 } ;
__p += '\n                        <div class="col-xs-1 price"><div class="col">' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 factor"><div class="col">' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-1 imprint"><div class="col">' +
((__t = ( _.i18n("print.imprint") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 handling"><div class="col">' +
((__t = ( _.i18n("price.handling") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 setup"><div class="col">' +
((__t = ( _.i18n("print.setup") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 smOrdFee"><div class="col">' +
((__t = ( _.i18n("price.smOrdFee") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 shipping"><div class="col">' +
((__t = ( _.i18n("price.shipping") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 total"><div class="col">' +
((__t = ( _.i18n("price.total") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 total-unit"><div class="col">' +
((__t = ( _.i18n("price.unit-price") )) == null ? '' : __t) +
'&nbsp;&euro;</div></div>\n                        <div class="col-xs-1 marginPercent"><div class="col">' +
((__t = ( _.i18n("price.marginPercent") )) == null ? '' : __t) +
'</div></div>\n                        <div class="col-xs-1 marginEuro"><div class="col">' +
((__t = ( _.i18n("price.marginEuro") )) == null ? '' : __t) +
'</div></div>\n                        ';
 if(!useFactorV2) { ;
__p += '\n                            <div class="col-xs-1 delete"><div class="col">' +
((__t = ( _.i18n("common.delete") )) == null ? '' : __t) +
'</div></div>\n                        ';
 } else { ;
__p += '\n                            <div class="col-xs-1" style="width: 14.2%;"><div class="col">' +
((__t = ( _.i18n("price.buyingPrice") )) == null ? '' : __t) +
'</div></div>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>   \n                <div class="scales-region"></div>\n                ';
 if (!useFactorV2) { ;
__p += '\n                <div class="btn btn-add-scale p-l-0 p-r-0 p-t-0 p-b-0">\n                    <i class="mdi-content-add"></i>\n                </div>\n                ';
 } ;
__p += '\n            </div> \n        </div> \n    </div>                   \n</div>\n\n<div class="row">\n    <div class="pull-right">\n        <button class="btn-save-product">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n        <br><br>\n        <p class="lblModDate"></p>\n    </div>\n</div>\n\n<div class="row">\n    <div class="col-xs-12 col-lg-6 competitors-region">\n    </div>\n    <div class="col-xs-12 col-lg-6 similars-region">\n    </div>\n</div>\n\n<div class="row" style="padding-bottom: 40px;">\n    <div class="col-xs-12">\n        \n        <div class="small-loading-block js-app-loading loader pull-right"></div>\n    </div>\n</div>\n<div class="loading-block js-loading-block loader"></div>';

}
return __p
};});

