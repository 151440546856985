/**
 * Created by RKL on 23/09/2015.
 */
define('caccountShareLayoutView',[
  'module',
  'marionette',
  'app',
  'template!caccountShareLayoutTpl'
], function(
  module,
  Marionette,
  App,
  layoutTpl
) {
  'use strict';

  var CAccountShareTabLayoutView = Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      accountShareFrom: '.share-from-container'
    }
  });

  module.exports = CAccountShareTabLayoutView;
});
