define('ordersDetailOrderProductStockView',[
  'module',
  'backbone',
  'underscore',
  'app',
  'moment',
  'ordersDetailOrderProductStockIconView',
  'tableView'
], function (
    Module,
    Backbone,
    _,
    App,
    moment,
    IconView,
    TableView
) {
  'use strict';

  Module.exports = TableView.extend({

    className: 'orders-detail-order-product-stock-view table-view',
    pageSize: 20,
    setFields: function() {
      return [{
        name: 'icon',
        className: 'icon',
        view: IconView
      },{
        name: 'owner',
        label: _.i18n('offerList.productOwner'),
        className: 'owner',
        onRender: _.bind(function(view) {
          view.$el.html(this.model.get('productOwner') ? this.model.get('productOwner') : '-');
        }, this)
      }, {
        name: 'code',
        label: _.i18n('common.code'),
        className: 'code',
        onRender: function(view) {
          view.$el.html(view.model.get('code') ? $.trim(view.model.get('code')) : '-');
        }
      }, {
        name: 'options',
        label: _.i18n('common.options'),
        className: 'options',
        onRender: function(view) {
          view.$el.html(view.model.get('productOptions') ? $.trim(view.model.get('productOptions')) : '-');
        }
      }, {
        name: 'currentqty',
        label: _.i18n('product.stock.currentqty'),
        className: 'currentqty',
        onRender: function(view) {
          view.$el.html(view.model.get('currentQuantity') ? view.model.get('currentQuantity') : '-');
        }
      }, {
        name: 'nextdate',
        label: _.i18n('product.stock.nextdate'),
        className: 'nextdate',
        onRender: function(view) {
          view.$el.html(view.model.get('newArrivalETA') ? view.model.get('newArrivalETA') : '-');
        }
      }, {
        name: 'nextqty',
        label: _.i18n('product.stock.nextqty'),
        className: 'nextqty',
        onRender: function(view) {
          view.$el.html(view.model.get('newArrivalQuantity') ? view.model.get('newArrivalQuantity') : '-');
        }
      }];
    },

    fetch: function(first, pageSize) {
      var defer = $.Deferred();

      var stockList = [];
      var stockData = {};

      this.options.product.fetchStock().then(_.bind(function(stocks) {
        this.options.product.computeStock(stockData, stocks, this.model.get('productCode'));
        _.each(stockData.stock, function (item) {
          var stock = {};

          stock.code = item.code;
          stock.lastUpdate = moment(new Date()).diff(moment(item.modDate), 'hours');

          if (stock.lastUpdate === 0)
            stock.lastUpdateMinutes = moment(new Date()).diff(moment(item.modDate), 'minutes');

          stock.upToDate = stock.lastUpdate < 4;
          stock.productOptions = '-';
          stock.currentQuantity = '-';
          stock.newArrivalETA = '-';
          stock.newArrivalQuantity = '-';

          stock.selected = item.code === this.model.get('productCode');

          if (this.model.get('productOwner') !== this.model.get('caccount').name) {
            stock.productOptions = item.productOptions;
            stock.currentQuantity = item.currentQuantity;
            stock.newArrivalETA = item.newArrivalETA;
            stock.newArrivalQuantity = item.newArrivalQuantity;
          }

          //if ((item.noStockInfo !== true && (item.currentQuantity > 0 || stock.newArrivalQuantity > 0)))
            stockList.push(stock);

        }, this);

        if (stockList.length === 0) {
          var stock = {};

          stock.code = this.model.get('productCode');
          stock.productOptions = '-';
          stock.currentQuantity = '-';
          stock.newArrivalETA = '-';
          stock.newArrivalQuantity = '-';
          stockList.push(stock);
        }

        var models = [];

        var list = stockList.slice(first, first + pageSize);

        _.each(list, _.bind(function(item) {
          var model = new Backbone.Model(item);
          models.push(model);
        }, this));

        defer.resolve(models);
      }, this));

      return defer.promise();
    }
  });
});
